import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withUnpublishedPreview } from 'gatsby-source-prismic'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Generic
import BasicPage from '../components/shared/BasicPage/BasicPage'
import HtmlContent from '../components/shared/HtmlContent/HtmlContent'
import Button from '../components/shared/Button/Button'
import Image from '../components/shared/Image/Image'

// Template
import PageTemplate from '../templates/PageTemplate'

const PageNotFound = (props) => {
  return (
    <>
      <Seo title='404' />
      <BasicPage>
        <div className='text-center'>
          {props.data.errorPage.data.image.url && (
            <div className='max-w-sm mx-auto mb-10'>
              <Image
                fallbackAlt='Error 404'
                {...props.data.errorPage.data.image}
              />
            </div>
          )}
          <h1 className='text-xs tracking-wider text-midnight text-opacity-75 font-medium'>404</h1>
          <h2 className='mb-4'>{props.data.errorPage.data.title.text}</h2>
          <HtmlContent
            html={props.data.errorPage.data.body.html}
            className='mb-8 c-prose'
          />
          <div className='flex justify-center items-center flex-wrap'>
            {props.data.errorPage.data.ctas.map((cta, ctaIndex) => {
              return (
                <Button
                  key={ctaIndex}
                  className='mx-2 mb-4'
                  color={cta.cta_style}
                  icon
                  {...cta.cta_link}
                >
                  {cta.cta_label}
                </Button>
              )
            })}
          </div>
        </div>
      </BasicPage>
    </>
  )
}

PageNotFound.propTypes = {
  data: PropTypes.object.isRequired
}

export default withUnpublishedPreview(PageNotFound, {
  templateMap: {
    page: PageTemplate
  }
})

export const PageQuery = graphql`
  query ErrorPage {
    errorPage: prismicErrorPage {
      data {
        title {
          text
        }
        body {
          html
        }
        image {
          ...Image
        }
        ctas {
          cta_label
          cta_link {
            ...Link
          }
          cta_style
        }
      }
    }
  }
`
