import React from 'react'

// Generic
import Container from '../Container/Container'

const BasicPage = (props) => {
  return (
    <>
      <div className='bg-offwhite h-64 lg:h-152' />
      <Container width='thinnest' className='pb-32'>
        <article className='-mt-32 lg:-mt-80 bg-white py-10 px-4 sm:p-10 md:p-20 lg:p-24 shadow-lg border-t-8 border-primary'>
          {props.children}
        </article>
      </Container>
    </>
  )
}

export default BasicPage
